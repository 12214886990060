import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { ToReferenceFunction } from '@apollo/client/cache/core/types/common';

import { ContentfulSpace } from './types';

interface SpaceConfig {
  uri: string;
  accessToken: string;
  previewAccessToken: string;
}

const { CONTENTFUL_GRAPHQL_URL: graphqlUrl = 'graphql.contentful.com' } =
  process.env;

const spaceConfig: Record<ContentfulSpace, SpaceConfig> = {
  expeditionsB2c: {
    uri: `https://${graphqlUrl}/content/v1/spaces/${
      process.env.CONTENTFUL_B2C_EXPEDITION_SPACE_ID ?? ''
    }/environments/${process.env.CONTENTFUL_B2C_ENVIRONMENT ?? ''}`,
    accessToken: process.env.CONTENTFUL_B2C_EXPEDITION_ACCESS_TOKEN ?? '',
    previewAccessToken:
      process.env.CONTENTFUL_B2C_EXPEDITION_PREVIEW_TOKEN ?? ''
  }
};

const clientInstances: Record<
  ContentfulSpace,
  Record<
    'preview' | 'regular',
    Record<TContentfulLanguage, ApolloClient<NormalizedCacheObject> | null>
  >
> = {
  expeditionsB2c: {
    regular: {
      'en-AU': null,
      'en-GB': null,
      'en-US': null,
      'de-DE': null,
      'gsw-CH': null,
      'da-DK': null,
      'sv-SE': null,
      'nb-NO': null,
      'fr-FR': null,
      en: null
    },
    preview: {
      'en-AU': null,
      'en-GB': null,
      'en-US': null,
      'de-DE': null,
      'gsw-CH': null,
      'da-DK': null,
      'sv-SE': null,
      'nb-NO': null,
      'fr-FR': null,
      en: null
    }
  }
};

const cacheTypes: Record<ContentfulSpace, string[]> = {
  expeditionsB2c: [
    'Voyage',
    'Excursion',
    'Program',
    'Destination',
    'Excursion',
    'Ship',
    'SeasonalityDestination',
    'HighlightGroup',
    'Highlight',
    'InspirationArticle'
  ]
};

// These will not be cached individually, but as part of the
// types above.
const doNotCacheTypes: Record<ContentfulSpace, string[]> = {
  expeditionsB2c: [
    'SeasonalityProperties',
    'SeasonalityMonths',
    'SeasonalityRowItem',
    'SeasonalityBarChartItem',
    'SeasonalityPopup',
    'ImageWrapper',
    'VideoWrapper',
    'VoyageAvailability',
    'HeroBanner',
    'Asset'
  ]
};

// Needed to make sure types are normalized and cached properly
const createKeyFieldsConfigForTypes = (
  cache: string[],
  doNotCache: string[]
) => {
  const policies: Record<string, { keyFields: ['sys', ['id']] | false }> = {};
  cache.forEach((type) => {
    policies[type] = { keyFields: ['sys', ['id']] };
  });
  doNotCache.forEach((type) => {
    policies[type] = { keyFields: false };
  });
  return policies;
};

// Needed in order to read type directly from cache by using id
const createReadFunctionForType =
  (type: string) =>
  (
    _: any,
    {
      args,
      toReference
    }: {
      args: Record<string, any> | null;
      toReference: ToReferenceFunction;
    }
  ) => {
    try {
      return toReference({
        __typename: type,
        sys: { id: args?.id }
      });
    } catch (error) {
      console.error(
        `Unable to create reference for type "${type}" with id "${String(
          args?.id
        )}"`,
        args
      );
      return undefined;
    }
  };

const createClient = (
  space: ContentfulSpace,
  locale: TContentfulLanguage,
  preview: boolean = false
) =>
  new ApolloClient({
    uri: spaceConfig[space].uri,
    cache: new InMemoryCache({
      typePolicies: {
        ...createKeyFieldsConfigForTypes(
          cacheTypes[space],
          doNotCacheTypes[space]
        ),
        Query: {
          fields: {
            destination: createReadFunctionForType('Destination'),
            program: createReadFunctionForType('Program'),
            excursion: createReadFunctionForType('Excursion'),
            voyage: createReadFunctionForType('Voyage')
          }
        }
      }
    }),
    headers: {
      authorization: `Bearer ${
        preview
          ? spaceConfig[space].previewAccessToken
          : spaceConfig[space].accessToken
      }`
    },
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        variables: {
          locale,
          preview
        }
      },
      watchQuery: {
        errorPolicy: 'all',
        variables: {
          locale,
          preview
        }
      }
    }
  });

export const getApolloClient = (
  space: ContentfulSpace,
  locale: TContentfulLanguage,
  preview: boolean = false
) => {
  let client = clientInstances[space][preview ? 'preview' : 'regular'][locale];

  if (client === null) {
    client = createClient(space, locale, preview);
    clientInstances[space][preview ? 'preview' : 'regular'][locale] = client;
  }

  return client;
};

const getHttpLink = (preview: boolean = false) =>
  new HttpLink({
    uri: process.env.CONTENT_API_URL,
    headers: {
      'x-client-name':
        process.env.CONTENT_API_CLIENT_NAME || `nellie.portal-dev`,
      Authorization: `Bearer ${process.env.CONTENT_API_ACCESS_TOKEN || ''}`,
      'x-content-preview': preview.toString()
    }
  });

const createContentApiClient = (preview: boolean = false) =>
  new ApolloClient({
    // We must have a cache
    cache: new InMemoryCache(),
    // But we can disable it by default for all requests
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    },
    link: getHttpLink(preview)
  });

const contentApiClientInstances = {
  preview: createContentApiClient(true),
  regular: createContentApiClient(false)
};
export const getContentApiClient = (preview: boolean = false) =>
  contentApiClientInstances[preview ? 'preview' : 'regular'];
